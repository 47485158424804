import React, { useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import './App.css';
import { v4 as uuidv4 } from 'uuid';

const numberWords = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
};

function CameraControls() {
  useFrame(({ camera }) => {
    camera.position.set(0, 0, 0.5); // Adjust the camera position to be close to the face
    camera.lookAt(0, 0, 0); // Make the camera look at the origin
  });
  return null;
}

function App() {
  const [setupText, setSetupText] = useState('Microphone permission is required to play the game.');
  const [question, setQuestion] = useState('');
  const [result, setResult] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isTalking, setIsTalking] = useState(false);
  const [isDancing, setIsDancing] = useState(false);
  const [isMicrophoneGranted, setIsMicrophoneGranted] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const main_url = 'triviamania.ai';

  useEffect(() => {
    window.playerCount = 0;
    window.currentPlayer = 0;
    window.scores = [];
    window.category = '';
    window.answer = '';
    window.questionsAsked = 0;
    window.correctAnswers = 0;
    window.gameId = '';
  }, []);

  const fetchQuestion = async () => {
    const response = await fetch(`https://${main_url}/trivia_back.php?category=${encodeURIComponent(window.category)}&gameId=${window.gameId}`);
    const data = await response.json();
    return data;
  };

  const fetchBackgroundImage = async (prompt) => {
    try {
      const response = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer sk-proj-9a2FpA1q79lopGo5m8LZT3BlbkFJ56qsdjynAH9pGjIgqOpw`,
        },
        body: JSON.stringify({
          prompt,
          n: 1,
          size: '1024x1024',
        }),
      });
      const data = await response.json();
      if (data && data.data && data.data.length > 0 && data.data[0].url) {
        setImageUrl(data.data[0].url);
      } else {
        console.error('No image found in the response.');
      }
    } catch (error) {
      console.error('Error fetching background image:', error);
    }
  };

  const askQuestion = async () => {
    setResult('');
    const data = await fetchQuestion();
    setQuestion(`Player ${window.currentPlayer + 1}: ${data.question}`);
    window.answer = data.answer;
    window.question = data.question;
    window.questionsAsked += 1;
    fetchBackgroundImage(data.question); // Fetch background image based on the question
    speakQuestion(`Player ${window.currentPlayer + 1}, your question is: ${data.question}`);
  };

  const speakQuestion = (text) => {
    setIsTalking(true);
    const speech = new SpeechSynthesisUtterance(text);
    speech.onend = () => {
      setIsTalking(false);
      startRecognition();
    };
    speechSynthesis.speak(speech);
  };

  const speakText = (text) => {
    setIsTalking(true);
    const speech = new SpeechSynthesisUtterance(text);
    speech.onend = () => setIsTalking(false);
    speechSynthesis.speak(speech);
  };

  const startRecognition = () => {
    setIsListening(true);
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = 'en-US';
    recognition.start();

    let answered = false;

    recognition.onresult = async (event) => {
      const userAnswer = event.results[0][0].transcript;
      setResult(`You said: ${userAnswer}`);
      answered = true;

      const response = await fetch(`https://${main_url}/check_answer.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userAnswer: userAnswer,
          correctAnswer: window.answer,
          question: window.question
        })
      });

      const data = await response.json();

      if (data.isCorrect) {
        setResult((prev) => prev + ' - Correct! Well done!');
        window.correctAnswers += 1;
        updateScore(window.currentPlayer);
        speakText('Correct! Well done!');
        setIsDancing(true);
        setTimeout(() => setIsDancing(false), 3000); // Dance for 3 seconds
      } else {
        setResult((prev) => prev + ` - Incorrect. The correct answer was ${window.answer}.`);
        speakText(`Incorrect. The correct answer was ${window.answer}.`);
      }

      setIsListening(false);
      setTimeout(nextQuestion, 1000);
    };

    recognition.onerror = (event) => {
      setResult(`Error occurred in recognition: ${event.error}`);
      setIsListening(false);
      setTimeout(nextQuestion, 1000);
    };

    setTimeout(() => {
      if (!answered) {
        recognition.stop();
        setResult(`No answer. The correct answer was ${window.answer}.`);
        speakText(`No answer. The correct answer was ${window.answer}.`);
        setIsListening(false);
        setTimeout(nextQuestion, 3000);
      }
    }, 5000);
  };

  const updateScore = (index) => {
    window.scores[index] += 1;
  };

  const nextQuestion = async () => {
    window.currentPlayer = (window.currentPlayer + 1) % window.playerCount;
    await askQuestion();
  };

  const listenForCategory = () => {
    setSetupText('Listening for category...');
    const categoryPrompt = new SpeechSynthesisUtterance('Please choose a category. Say a category such as movies, music, or TV shows.');
    speechSynthesis.speak(categoryPrompt);

    categoryPrompt.onend = () => {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = 'en-US';
      recognition.start();

      recognition.onresult = (event) => {
        const selectedCategory = event.results[0][0].transcript;
        window.category = selectedCategory;
        setSetupText(`Category selected: ${selectedCategory}`);
        speakText(`Category selected: ${selectedCategory}`);
        fetchBackgroundImage(selectedCategory); // Fetch background image based on the category
        setTimeout(askQuestion, 1000); // Proceed to the first question after category selection
      };

      recognition.onerror = (event) => {
        setSetupText(`Error occurred in recognition: ${event.error}`);
        setTimeout(listenForCategory, 1000);
      };
    };
  };

  const listenForPlayerCount = () => {
    setSetupText('Listening for the number of players...');
    const playerCountPrompt = new SpeechSynthesisUtterance('How many players? Please say a number between one and eight.');
    speechSynthesis.speak(playerCountPrompt);

    playerCountPrompt.onend = () => {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = 'en-US';
      recognition.start();

      recognition.onresult = (event) => {
        const playerNumberStr = event.results[0][0].transcript.trim().toLowerCase();
        const playerNumber = numberWords[playerNumberStr] || parseInt(playerNumberStr, 10);
        if (!isNaN(playerNumber) && playerNumber >= 1 && playerNumber <= 8) {
          window.playerCount = playerNumber;
          window.currentPlayer = 0;
          window.scores = Array.from({ length: window.playerCount }, () => 0);
          setSetupText(`Number of players: ${playerNumber}`);
          speakText(`Number of players: ${playerNumber}`);
          setTimeout(listenForCategory, 1000);
        } else {
          setSetupText('Invalid number. Please say a number between one and eight.');
          speakText('Invalid number. Please say a number between one and eight.');
          setTimeout(listenForPlayerCount, 1000);
        }
      };

      recognition.onerror = (event) => {
        setSetupText(`Error occurred in recognition: ${event.error}`);
        setTimeout(listenForPlayerCount, 1000);
      };
    };
  };

  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsMicrophoneGranted(true);
      // Turn off the microphone
      stream.getTracks().forEach(track => track.stop());
      setSetupText('Microphone permission granted. Click "Start Game" to begin.');
    } catch (error) {
      setSetupText('Microphone permission is required to play the game.');
    }
  };

  const startGame = async () => {
    const newGameId = uuidv4();
    window.gameId = newGameId;
    setSetupText(`Game ID: ${newGameId}`);
    listenForPlayerCount();
  };

  return (
      <div className="container" style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="content">
          <div className="card">
            <div className="card-content">
              <img
                  src="trivia_mania_logo.png"
                  alt="Trivia Mania Logo"
                  className="card-title responsive-image"
              />              <p id="setup" className="flow-text">{setupText}</p>
              <p id="question" className="flow-text">{question}</p>
              <p id="result" className="flow-text">{result}</p>
              <div id="score" className="flow-text">
                {window.playerCount > 0 && window.scores.map((score, index) => (
                    <div key={index} className={`player-score player-${index + 1}`}>Player {index + 1}: {score}</div>
                ))}
              </div>
            </div>
            <div className="card-action">
              {!isMicrophoneGranted && (
                  <button id="startButton" className="btn" onClick={requestMicrophonePermission}>Request Microphone Permission</button>
              )}
              {isMicrophoneGranted && !isListening && (
                  <button id="startGameButton" className="btn" onClick={startGame}>Start Game</button>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
